import React, { useMemo } from "react";
import { admin_talent_project as $ } from "strings";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import Header from "components/ListHeader";
import UilArrow from "@iconscout/react-unicons/icons/uil-compress-alt";
import UilUser from "@iconscout/react-unicons/icons/uil-user";
import LoadPlaceholder from "components/LoadPlaceholder";
import UilInfoCircle from "@iconscout/react-unicons/icons/uil-info-circle";
import {
  getProjectOwnersV2,
  getProjectCesV2,
  getProjectOpsV2
} from "utils/adminApi";
import useFetch from "hooks/useFetch";

const Stakeholders = ({ projectId, open, onTogglePanel }) => {
  const { data: ownersData } = useFetch(getProjectOwnersV2, projectId);
  const { data: cesData } = useFetch(getProjectCesV2, projectId);
  const { data: opsData } = useFetch(getProjectOpsV2, projectId);

  const owners = useMemo(() => {
    if (!ownersData || !ownersData.owners) {
      return null;
    }
  
    const ownersArr = [];

    if (ownersData.owners && Array.isArray(ownersData.owners)) {
      ownersData.owners.forEach((o) => {
        ownersArr.push(o.name)
      });
    }

    return ownersArr;
  }, [ownersData]);

  const ces = useMemo(() => {
    if (!cesData || !cesData.project_ces) {
      return null;
    }
    const cesArr = [];

    if (cesData.project_ces && Array.isArray(cesData.project_ces)) {
      cesData.project_ces.forEach((ce) => {
        cesArr.push(ce.name)
      });
    }

    return cesArr;
  }, [cesData]);

  const ops = useMemo(() => {
    if (!opsData || !opsData.ops_owner) {
      return null;
    }
    
    return opsData.ops_owner.name;
  }, [opsData]);

  return (
    <Section id="project_stakeholders" className="flex mb-1">
      <div className="bg-white flex-1 mr-2 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilInfoCircle className="mr-2" size="18" />{$.stakeholders_title}
          </B2DB>
          <div className="cursor-pointer" onClick={onTogglePanel}>
            <UilArrow size="18" />
          </div>
        </div>
        {open && (
          <div className="px-2 pb-4">
            {(!ownersData || !cesData || !opsData) && (
              <div className="flex-1 border border-link-water rounded px-6 pt-6">
                <LoadPlaceholder className="w-40 h-4 my-4" />
                <LoadPlaceholder className="w-40 h-4 mb-4" />
                <LoadPlaceholder className="w-20 h-4 mb-2" />
              </div>
            )}
            {owners && ces && opsData && (
              <>
                <div className="w-full flex flex-col mb-2">
                  <Header Icon={UilUser} text={$.project_owners_title} />
                  <div className="text-sm text-midnight font-bold text-ellipsis">
                    {owners.length ? owners.map((o, i) => (
                      <span>{o}{i === owners.length - 1 ? '' : ', '}</span>
                    )) : $.unknown_value}
                  </div>
                </div>
                <div className="w-full flex flex-col mb-2">
                  <Header Icon={UilUser} text={$.project_ce_title} />
                  <div className="text-sm text-midnight font-bold text-ellipsis">
                    {ces.length ? ces.map((ce, i) => (
                      <span>{ce}{i === ces.length - 1 ? '' : ', '}</span>
                    )) : $.unknown_value}
                  </div>
                </div>
                <div className="w-full flex flex-col">
                  <Header Icon={UilUser} text={$.ops_support_title} />
                  <div className="text-sm text-midnight font-bold text-ellipsis">
                    {ops || $.unknown_value}
                  </div>
                </div>
              </>
            )}
            </div>
          )}
      </div>
    </Section>
  )
};

export default Stakeholders;