import React, { useState } from "react";
import {useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { admin_talent_invitation_form as $ } from "strings";
import { global as $$ } from "strings";
import { Header, Footer } from "components/Card";
import { Input, Label, Error } from "components/Form";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import { patchUser, postTalentEmailInvite } from "utils/adminApi";
import { isTemporalEmail } from "utils/str";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { B1 } from "components/Typography";
import AlertSuccess from "../../../components/AlertSuccess";

const InvitationForm = ({ onClose, setData, data }) => {
  const alert = useAlert();
  const { id } = useParams();
  const [emailError, setEmailError] = useState(false);

  const isOriginalTemporal = isTemporalEmail(data.primary_talent_email);

  const { handleSubmit, register, errors, formState } = useForm({
    defaultValues: {
      firstname: data.first_name,
      lastname: data.last_name,
      email: isOriginalTemporal ? "" : data.primary_talent_email,
    },
  });

  const onSubmit = async (values) => {
    setEmailError(false);

    const origValues = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.primary_talent_email
    };

    const userValues = {
      first_name: values.firstname,
      last_name: values.lastname,
      email: values.email
        ? values.email
        : isOriginalTemporal
        ? data.primary_talent_email
        : "",
    };

    setData({
      ...data,
      ...userValues,
      onboard_last_email_at: new Date().toISOString(),
    });

    const call = async () => {
      try {
        // await patchAccountV2(id, userValues);
        await patchUser(id, userValues);
        await postTalentEmailInvite({
          email: userValues.email,
        });
        alert.success(<AlertSuccess message={$$.successful_request_info} />, {timeout: 2000});
        onClose();
      } catch (e) {
        if (e.response.status === 400 && e.response.data[0].includes('duplicate')) {
          setEmailError(true);
          setData({...data, ...origValues})
        } else {
          onClose();
          alert.error(<AlertError error={e} onRetry={call} />);
        }
      }
    };
    await call();
  };

  return (
    <Modal
      isDirty={formState.isDirty}
      onClose={onClose}
      id="admin_talent_invitation_form"
    >
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Header
          title={
            "Invite " +
            data.first_name +
            " " +
            data.last_name +
            " to create a profile on Pipeline?"
          }
        />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          <div className="w-full pr-8 mb-3">
            <B1>{$.info_text}</B1>
          </div>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.firstname_input}</Label>
            <Input
              name="firstname"
              maxLength="254"
              ref={register({ required: $.validation_required })}
              error={errors.firstname}
            />
            {errors.firstname && <Error>{errors.firstname.message}</Error>}
          </div>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.lastname_input}</Label>
            <Input
              name="lastname"
              maxLength="254"
              ref={register({ required: $.validation_required })}
              error={errors.lastname}
            />
            {errors.lastname && <Error>{errors.lastname.message}</Error>}
          </div>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.email_input}</Label>
            <Input
              name="email"
              type="email"
              maxLength="254"
              ref={register({
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                  message: $.validation_email,
                },
              })}
              onChange={() => setEmailError(false)}
              error={errors.email}
            />
            {errors.email && <Error>{errors.email.message}</Error>}
            {emailError && <Error>{$.duplicate_email_error}</Error>}
          </div>
        </div>
        <Footer>
          <Button secondary onClick={onClose} className="mr-4">
            {$.cancel_button}
          </Button>
          <Button type="submit" loading={formState.isSubmitting} disabled={formState.isSubmitting}>
            {$.save_button}
          </Button>
        </Footer>
      </form>
    </Modal>
  );
};

export default InvitationForm;
