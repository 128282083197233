import React, { useEffect, useMemo, useState } from "react";
import { admin_talent_merge as $ } from "strings";
import $$ from "strings/onboard";
import {
  getTalentDiff,
  getTalentLinks,
  getTalentProjects,
  getTalentQualifications,
  postMergeTalents,
  getTalentOwners,
  getTalentLeadSource,
  getTalentFiles,
} from "utils/adminApi";
import UilCheck from "@iconscout/react-unicons/icons/uil-check-square";
import UilTimes from "@iconscout/react-unicons/icons/uil-times-square";
import UilSquare from "@iconscout/react-unicons/icons/uil-square";
import { toFormat } from "utils/date";
import userImg from "assets/user.svg";
import { formatPhone } from "utils/str";
import UilUnion from "@iconscout/react-unicons/icons/uil-pathfinder-unite";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import Rating from "components/Rating";

const gradeOptions = {
  8: "A+",
  7: "A",
  6: "A-",
  5: "B+",
  4: "B",
  3: "B-",
  2: "C",
};

const seniorityOptions = {
  associate: $.associate_option,
  manager: $.manager_option,
  senior_manager: $.senior_manager_option,
  director: $.director_option,
  vp: $.vp_option,
};

const specialtiesOptions = [
  { label: $$.performance_marketing_option, value: 'performance_marketing' },
  { label: $$.lifecycle_crm_option, value: 'lifecycle_crm' },
  { label: $$.content_marketing_option, value: 'content_marketing' },
  { label: $$.product_marketing_option, value: 'product_marketing' },
  { label: $$.analytics_option, value: 'analytics' },
  { label: $$.marketing_ops_option, value: 'marketing_ops' },
  { label: $$.affiliate_option, value: 'affiliate' },
  { label: $$.ecommerce_option, value: 'ecommerce' },
  { label: $$.design_option, value: 'design' },
  { label: $$.leadership_option, value: 'leadership' },
  { label: $$.brand_marketing_option, value: 'brand_marketing' }
]

const employmentStatusOptions = [
  {label: $$.w2_employee_option, value: 'w2_employee'},
  {label: $$.freelancer_option, value: 'freelancer'},
  {label: $$.student_option, value: 'student'},
  {label: $$.searching_for_w2_option, value: 'searching_for_w2'},
  {label: $$.open_to_fulltime_freelance_option, value: 'open_to_fulltime_freelance'}
];

const workingTimezonesOptions = [
  {label: 'Eastern', value: 'eastern'},
  {label: 'Central', value: 'central'},
  {label: 'Mountain', value: 'mountain'},
  {label: 'Pacific', value: 'pacific'}
];

const onsiteFrequencyOptions = [
  {label: $$.regular_days_option, value: 'regular'},
  {label: $$.occasional_meetings_option, value: 'occasional'},
  {label: $$.special_events_option, value: 'special'},
  {label: $$.not_interested_travel_option, value: 'not_interested' }
];

const travelDistanceOptions = [
  {label: $$.local_travel_option, value: 'local'},
  {label: $$.domestic_travel_option, value: 'domestic'},
  {label: $$.international_travel_option, value: 'international'}
];

const rateFlexibilityOptions = [
  {label: $$.passion_project_option, value: 'PassionProject'},
  {label: $$.longer_contract_option, value: 'LongerContract'},
  {label: $$.more_hours_option, value: 'WeeklyWork'}
];

const businessHoursAvailableOptions = [
  {label: $$.yes_option, value: 'yes'},
  {label: $$.depends_on_day_option, value: 'depends_on_day'},
  {label: $$.no_option, value: 'no'}
];


const talentTypeOptions = {
  "Moonlighter": "Moonlighter",
  "FlexibleMoonlighter": "Flexible Moonlighter",
  "Freelancer": "Freelancer",
  "FTECandidateOnly": "FTE Candidate Only",
  "Unavailable": "Currently Unavailable"
}

const BooleanField = (v) => (
  <>{v === true ? $.yes_option : v === false ? $.no_option : ""}</>
);

const MoneyField = (v) => <>{v !== null || v !== undefined ? "$" + v : ""}</>;

const DateTimeField = (v) => (
  <>{v ? toFormat(new Date(v), "MM/dd/yyyy, hh:mm a") : ""}</>
);

const PhoneField = (v) => <>{v ? formatPhone(v) : ""}</>;

const formatPipeSeparatedValues = (v, referenceObj) => {
  if (!v || !referenceObj) return '';
  const arr = v.split('|');
  let formattedVal = '';
  arr.forEach(i => {
    const ref = referenceObj.find(item => item.value === i);
    if (referenceObj.find(item => item.value === i)) {
      formattedVal += `${ref.label}, `;
    }
  })
  if (formattedVal.slice(-2) === ', ') {
    formattedVal = formattedVal.slice(0, formattedVal.length - 2);
  }
  return formattedVal;
}

const schema = {
  User: {
    first_name: "",
    last_name: "",
    email: "",
    primary_talent_email: "",
    primary_client_email: "",
    primary_investor_email: "",
  },
  TalentAccount: {
    created_at: DateTimeField,
    onboarded: BooleanField,
    talent_grade: (v) => <>{gradeOptions[v]}</>,
    description: "",
    phone_number: PhoneField,
    location_id: (v, d) => <>{d.full_address}</>,
    timezone_id: "",
    availability_capacity: "",
    availability_expiration_date: DateTimeField,
    ideal_rate: MoneyField,
    minimum_rate: MoneyField,
    seniority: (v) => <>{seniorityOptions[v] || v}</>,
    fulltime_interest: BooleanField,
    talent_type: (v)=>  <>{talentTypeOptions[v] || v}</>,
    community: BooleanField,
    background_check_passed: BooleanField,
    profile_photo: (v, d, oS) => (
      <>
        <img
          src={v || userImg}
          className={"rounded w-16 h-16 " + (oS ? "filter grayscale" : "")}
          alt={d.user.first_name}
        />
      </>
    ),
    current_role: "",
    lead_source: (v, d, oS, e) => e.leadSource && <>{e.leadSource.name}</>,
    work_style: "",
    rate_flexibility: (v) => formatPipeSeparatedValues(v, travelDistanceOptions),
    years_of_experience: "",
    years_of_experience_updated_at: DateTimeField,
    travel_distance_preferences: (v) => formatPipeSeparatedValues(v, travelDistanceOptions),
    onsite_frequency: (v) => formatPipeSeparatedValues(v, onsiteFrequencyOptions),
    business_hours_available: (v) => formatPipeSeparatedValues(v, businessHoursAvailableOptions),
    working_timezones: (v) => formatPipeSeparatedValues(v, workingTimezonesOptions),
    employment_status: (v) => formatPipeSeparatedValues(v, employmentStatusOptions),
    specialties: (v) => formatPipeSeparatedValues(v, specialtiesOptions),
    skills_survey_submitted_at: DateTimeField,
  },
};

const Form = ({
  idA,
  idB,
  talentA,
  talentB,
  onCancel,
  linksA,
  qualificationsA,
  projectsA,
  ownersA,
  leadSourceA,
  filesA,
}) => {
  const [data, setData] = useState();
  const [linksB, setLinksB] = useState();
  const [qualificationsB, setQualificationsB] = useState();
  const [projectsB, setProjectsB] = useState();
  const [ownersB, setOwnersB] = useState();
  const [filesB, setFilesB] = useState();
  const [leadSourceB, setLeadSourceB] = useState();
  const [pageState, setPageState] = useState("loading");
  const [selected, setSelected] = useState({});
  const [saving, setSaving] = useState(false);
  const alert = useAlert();
  const history = useHistory();

  const handleSelect = (index, value) => {
    setSelected((s) => ({ ...s, [index]: value }));
  };

  const handleSelectLink = (index, value) => {
    setSelected((s) => ({ ...s, [index]: value }));
    const matchingFile = files.find(f => f.matchingLinkIndex === index);
    if (matchingFile) {
      setSelected((s) => ({...s, [`TalentAttachedFile__${matchingFile.name}`]: value}));
    }
  };

  const onSubmit = async () => {
    setSaving(true);
    const toSend = {
      final: {
        id: idA,
        fields: {
          // fields that should be KEPT in case of conflicts
          User: [],
          TalentAccount: [],
        },
        related: {
          User: {
            UserLink: {},
          },
          TalentAccount: {
            TalentQualification: {},
            TalentOwners: {},
            TalentAttachedFile: {},
            TalentProjects: {},
          },
        },
      },
      to_delete: {
        id: idB,
        fields: {
          // fields that should be COPIED in case of conflicts
          User: [],
          TalentAccount: [],
        },
        related: {
          User: {
            UserLink: {},
          },
          TalentAccount: {
            TalentQualification: {},
            TalentOwners: {},
            TalentAttachedFile: {},
            TalentProjects: {},
          },
        },
      },
    };

    Object.entries(selected).forEach(([s, v]) => {
      let toPush = [s];
      if (s === "location_id") {
        toPush = [
          s,
          "state",
          "state_code",
          "street",
          "street_number",
          "zipcode",
          "full_address",
          "locality",
          "neighborhood",
          "country",
          "city",
        ];
      } else if (s === "timezone_id") {
        toPush = [s, "timezone_name"];
      }

      const p = s.split("__");
      if (p[0] === "TalentLinks") {
        const f = (v === "A" ? linksA : linksB).find((l) => l.name === p[1]);

        if (f) {
          toSend[
            v === "A" ? "final" : "to_delete"
          ].related.User.UserLink[f.id] = true;
        }
      } else if (p[0] === "TalentOwners") {
        const f = (v === "A" ? ownersA : ownersB).find(
          (o) => o.owner.id === p[1]
        );

        if (f) {
          toSend[
            v === "A" ? "final" : "to_delete"
          ].related.TalentAccount.TalentOwners[f.id] = true;
        }
      } else if (p[0] === "TalentAttachedFile") {
        const f = (v === "A" ? filesA : filesB).find((o) => o.filename === p[1]);

        if (f) {
          toSend[
            v === "A" ? "final" : "to_delete"
          ].related.TalentAccount.TalentAttachedFile[f.id] = true;
        }
      } else if (p[0] === "TalentQualificationsRSU") {
        const f = (v === "A" ? qualificationsA : qualificationsB).find(
          (q) => q.qualification.id === p[1]
        );

        const j = (v === "A" ? qualificationsB : qualificationsA).find(
          (q) => q.qualification.id === p[1]
        );

        if (f && !j) {
          toSend[
            v === "A" ? "final" : "to_delete"
          ].related.TalentAccount.TalentQualification[f.id] = [
            ...(toSend[v === "A" ? "final" : "to_delete"].related.TalentAccount
              .TalentQualification[f.id] || []),
            "rsu_rating",
          ];
        }

        if (f && j) {
          const otherExists =
            !!toSend[v === "A" ? "to_delete" : "final"].related.TalentAccount
              .TalentQualification[j.id];
          if (v === "A" && otherExists) {
            toSend[
              v === "A" ? "final" : "to_delete"
            ].related.TalentAccount.TalentQualification[f.id] = true;
          } else if (v === "B" && otherExists) {
            toSend[
              v === "A" ? "to_delete" : "final"
            ].related.TalentAccount.TalentQualification[j.id] = true;
            toSend[
              v === "A" ? "final" : "to_delete"
            ].related.TalentAccount.TalentQualification[f.id] = [
              ...(toSend[v === "A" ? "final" : "to_delete"].related
                .TalentAccount.TalentQualification[f.id] || []),
              "rsu_rating",
            ];
          } else {
            toSend[
              v === "A" ? "final" : "to_delete"
            ].related.TalentAccount.TalentQualification[f.id] = [
              ...(toSend[v === "A" ? "final" : "to_delete"].related
                .TalentAccount.TalentQualification[f.id] || []),
              "rsu_rating",
            ];
          }
        }
      } else if (p[0] === "TalentQualificationsTalent") {
        const f = (v === "A" ? qualificationsA : qualificationsB).find(
          (q) => q.qualification.id === p[1]
        );

        const j = (v === "A" ? qualificationsB : qualificationsA).find(
          (q) => q.qualification.id === p[1]
        );

        if (f && !j) {
          toSend[
            v === "A" ? "final" : "to_delete"
          ].related.TalentAccount.TalentQualification[f.id] = [
            ...(toSend[v === "A" ? "final" : "to_delete"].related.TalentAccount
              .TalentQualification[f.id] || []),
            "talent_rating",
          ];
        }

        if (f && j) {
          const otherExists =
            !!toSend[v === "A" ? "to_delete" : "final"].related.TalentAccount
              .TalentQualification[j.id];
          if (v === "A" && otherExists) {
            toSend[
              v === "A" ? "final" : "to_delete"
            ].related.TalentAccount.TalentQualification[f.id] = true;
          } else if (v === "B" && otherExists) {
            toSend[
              v === "A" ? "to_delete" : "final"
            ].related.TalentAccount.TalentQualification[j.id] = true;
            toSend[
              v === "A" ? "final" : "to_delete"
            ].related.TalentAccount.TalentQualification[f.id] = [
              ...(toSend[v === "A" ? "final" : "to_delete"].related
                .TalentAccount.TalentQualification[f.id] || []),
              "talent_rating",
            ];
          } else {
            toSend[
              v === "A" ? "final" : "to_delete"
            ].related.TalentAccount.TalentQualification[f.id] = [
              ...(toSend[v === "A" ? "final" : "to_delete"].related
                .TalentAccount.TalentQualification[f.id] || []),
              "talent_rating",
            ];
          }
        }
      } else if (s === "projects") {
        const f = (v === "A" ? projectsA : projectsB).map((p) => p.id);

        f.forEach((fi) => {
          toSend[
            v === "A" ? "final" : "to_delete"
          ].related.TalentAccount.TalentProjects[fi] = true;
        });
      } else {
        toSend[v === "A" ? "final" : "to_delete"].fields[
          schema.User[s] === "" ? "User" : "TalentAccount"
        ].push(...toPush);
      }
    });

    Object.entries(
      toSend.to_delete.related.TalentAccount.TalentQualification
    ).forEach(([k, q]) => {
      let v = q;
      if (
        Array.isArray(q) &&
        q.includes("talent_rating") &&
        q.includes("rsu_rating")
      ) {
        v = true;
      }

      toSend.to_delete.related.TalentAccount.TalentQualification[k] = v;
    });

    Object.entries(
      toSend.final.related.TalentAccount.TalentQualification
    ).forEach(([k, q]) => {
      let v = q;
      if (
        Array.isArray(q) &&
        q.includes("talent_rating") &&
        q.includes("rsu_rating")
      ) {
        v = true;
      }

      toSend.final.related.TalentAccount.TalentQualification[k] = v;
    });

    const call = async () => {
      setSaving(true);
      try {
        await postMergeTalents(idA, idB, toSend);
        history.push(`/admin/talent/${idA}/overview`);
      } catch (e) {
        setSaving(false);
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  };

  const { noConflicted, conflicted } = useMemo(() => {
    if (!data) {
      return {};
    }

    handleSelect(
      "clockify_id",
      (talentA.clockify_id && !talentB.clockify_id) ||
        (!talentA.clockify_id && !talentB.clockify_id)
        ? "A"
        : !talentA.clockify_id && talentB.clockify_id
        ? "B"
        : null
    );

    const noConflicted = [];
    const conflicted = [];
    Object.entries(schema).forEach(([i, m]) => {
      Object.entries(m).forEach(([k, r]) => {
        const vA = (i === "User" ? talentA.user : talentA)[k];
        const vB = (i === "User" ? talentB.user : talentB)[k];
        if (data.status[i]?.includes(k)) {
          conflicted.push({ index: k, model: i, vA, vB, render: r });
        } else {
          noConflicted.push({ index: k, model: i, vA, vB, render: r });
          handleSelect(k, vA ? "A" : !vB ? "A" : "B");
        }
      });
    });

    return { noConflicted, conflicted };
  }, [data, schema, talentA, talentB]);

  const projects = useMemo(() => {
    if (!projectsA || !projectsB) {
      return {};
    }

    const a = projectsA.map((p) => p.name + " at " + p.company).join("\n");
    const b = projectsB.map((p) => p.name + " at " + p.company).join("\n");

    if (a === b || !a || !b) {
      handleSelect("projects", !b || a === b ? "A" : "B");
    }

    return { a, b, c: a !== b && a && b };
  }, [projectsA, projectsB]);

  const { linksNoConflicted, linksConflicted } = useMemo(() => {
    if (!linksB || !linksA) {
      return {};
    }

    const linksNoConflicted = [];
    const linksConflicted = [];

    const bAdded = [];
    linksA.forEach((a) => {
      const b = linksB.find(
        (b) => b.name.toLowerCase() === a.name.toLowerCase()
      );

      if (b) {
        bAdded.push(b.id);

        if (b.url === a.url || !b.url) {
          linksNoConflicted.push({ a, b, name: a.name });
          handleSelect("TalentLinks__" + a.name, "A");
        } else {
          linksConflicted.push({ a, b, name: a.name });
        }
      } else {
        linksNoConflicted.push({ a, b: null, name: a.name });
        handleSelect("TalentLinks__" + a.name, "A");
      }
    });

    linksB.forEach((b) => {
      if (bAdded.includes(b.id)) {
        return;
      }

      linksNoConflicted.push({ a: null, b, name: b.name });
      handleSelect("TalentLinks__" + b.name, "B");
    });

    return { linksNoConflicted, linksConflicted };
  }, [linksA, linksB]);

  const ownersNoConflicted = useMemo(() => {
    if (!ownersB || !ownersA) {
      return [];
    }

    const ownersNoConflicted = [];

    const bAdded = [];
    ownersA.forEach((a) => {
      const b = ownersB.find((b) => b.owner.id === a.owner.id);

      if (b) {
        bAdded.push(b.owner.id);

        ownersNoConflicted.push({
          a,
          b,
          name: a.owner.first_name + " " + a.owner.last_name,
          index: a.owner.id,
        });
        handleSelect("TalentOwners__" + a.owner.id, "A");
      } else {
        ownersNoConflicted.push({
          a,
          b: null,
          name: a.owner.first_name + " " + a.owner.last_name,
          index: a.owner.id,
        });
        handleSelect("TalentOwners__" + a.owner.id, "A");
      }
    });

    ownersB.forEach((b) => {
      if (bAdded.includes(b.owner.id)) {
        return;
      }

      ownersNoConflicted.push({
        a: null,
        b,
        name: b.owner.first_name + " " + b.owner.last_name,
        index: b.owner.id,
      });
      handleSelect("TalentOwners__" + b.owner.id, "B");
    });

    return ownersNoConflicted;
  }, [ownersA, ownersB]);

  const files = useMemo(() => {
    if (!filesA || !filesB || !linksA || !linksB) return;

    const files = [];

    filesA.forEach(a => {
      const file = {
        a,
        b: null,
        name: a.filename,
        index: a.id,
      };
      const matchingLink = linksA.find(l => l.url === a.url);
      if (matchingLink) {
        file.matchingLinkIndex = `TalentLinks__${matchingLink.name}`;
      }
      files.push(file);
      const linkHasConflict = linksConflicted && linksConflicted.find(l => l.name.toLowerCase() === a.filename.toLowerCase());
      if (!linkHasConflict) {
        handleSelect("TalentAttachedFile__" + a.filename, "A");
      }
    });

    filesB.forEach(b => {
      const file = {
        a: null,
        b,
        name: b.filename,
        index: b.id,
      };
      const matchingLink = linksB.find(l => l.url === b.url);
      if (matchingLink) {
        file.matchingLinkIndex = `TalentLinks__${matchingLink.name}`;
      };
      files.push(file);
      const linkHasConflict = linksConflicted && linksConflicted.find(l => l.name.toLowerCase() === b.filename.toLowerCase());
      if (!linkHasConflict) {
        handleSelect("TalentAttachedFile__" + b.filename, "B");
      }
    });

    return files;
  }, [filesA, filesB, linksA, linksB, linksConflicted]);

  const { qualificationsNoConflicted, qualificationsConflicted } =
    useMemo(() => {
      if (!qualificationsB || !qualificationsA) {
        return {};
      }

      const qualificationsNoConflicted = [];
      const qualificationsConflicted = [];

      const bAdded = [];
      qualificationsA.forEach((a) => {
        const b = qualificationsB.find(
          (b) => b.qualification.id === a.qualification.id
        );

        if (b) {
          bAdded.push(b.id);

          if (b.rsu_rating === a.rsu_rating || !b.rsu_rating) {
            qualificationsNoConflicted.push({
              a,
              b,
              name: a.qualification.name,
              index: "TalentQualificationsRSU__" + a.qualification.id,
              type: "rsu_rating",
            });
            handleSelect("TalentQualificationsRSU__" + a.qualification.id, "A");
          } else {
            qualificationsConflicted.push({
              a,
              b,
              name: a.qualification.name,
              index: "TalentQualificationsRSU__" + a.qualification.id,
              type: "rsu_rating",
            });
          }

          if (b.talent_rating === a.talent_rating || !b.talent_rating) {
            qualificationsNoConflicted.push({
              a,
              b,
              name: a.qualification.name,
              index: "TalentQualificationsTalent__" + a.qualification.id,
              type: "talent_rating",
            });
            handleSelect(
              "TalentQualificationsTalent__" + a.qualification.id,
              "A"
            );
          } else {
            qualificationsConflicted.push({
              a,
              b,
              name: a.qualification.name,
              index: "TalentQualificationsTalent__" + a.qualification.id,
              type: "talent_rating",
            });
          }
        } else {
          qualificationsNoConflicted.push({
            a,
            b: null,
            name: a.qualification.name,
            index: "TalentQualificationsRSU__" + a.qualification.id,
            type: "rsu_rating",
          });
          qualificationsNoConflicted.push({
            a,
            b: null,
            name: a.qualification.name,
            index: "TalentQualificationsTalent__" + a.qualification.id,
            type: "talent_rating",
          });
          handleSelect("TalentQualificationsRSU__" + a.qualification.id, "A");
          handleSelect(
            "TalentQualificationsTalent__" + a.qualification.id,
            "A"
          );
        }
      });

      qualificationsB.forEach((b) => {
        if (bAdded.includes(b.id)) {
          return;
        }

        qualificationsNoConflicted.push({
          a: null,
          b,
          name: b.qualification.name,
          index: "TalentQualificationsRSU__" + b.qualification.id,
          type: "rsu_rating",
        });
        qualificationsNoConflicted.push({
          a: null,
          b,
          name: b.qualification.name,
          index: "TalentQualificationsTalent__" + b.qualification.id,
          type: "talent_rating",
        });
        handleSelect("TalentQualificationsRSU__" + b.qualification.id, "B");
        handleSelect("TalentQualificationsTalent__" + b.qualification.id, "B");
      });

      qualificationsNoConflicted.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      qualificationsConflicted.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );

      return { qualificationsNoConflicted, qualificationsConflicted };
    }, [qualificationsA, qualificationsB]);

  useEffect(() => {
    const load = async () => {
      try {
        const response = await getTalentDiff(idA, idB);

        const links = await getTalentLinks(idB);
        const qualifications = await getTalentQualifications(idB, {
          page_size: 9999,
        });
        const projects = await getTalentProjects(idB, { page_size: 9999 });
        const owners = await getTalentOwners(idB, { page_size: 9999 });
        const files = await getTalentFiles(idB);

        setData(response);
        setLinksB(links.results);
        setProjectsB(projects.results);
        setQualificationsB(qualifications.results);
        setOwnersB(owners.results);
        setFilesB(files.results);

        if (talentB.lead_source?.id) {
          const leadSource = await getTalentLeadSource(talentB.lead_source.id);
          setLeadSourceB(leadSource.data);
        }

        setPageState("loaded");
      } catch (e) {
        setPageState("error");
        console.error(e);
      }
    };
    load();
  }, [idA, idB, talentB]);

  if (!data || pageState !== "loaded") {
    return (
      <div className="flex items-center text-midnight font-bold justify-center pt-40">
        {$.loading_text}
      </div>
    );
  }

  if (saving) {
    return (
      <div className="flex items-center text-midnight font-bold justify-center pt-40">
        {$.saving_text}
      </div>
    );
  }

  const canMerge =
    (selected.clockify_id || (!talentA.clockify_id && !talentB.clockify_id)) &&
    !conflicted.some((c) => !selected[c.index]) &&
    !linksConflicted.some((l) => !selected["TalentLinks__" + l.name]) &&
    !qualificationsConflicted.some((q) => !selected[q.index]) &&
    (!projects.c || selected.projects);

  return (
    <div className="my-2">
      <div className="border-b border-geyser">
        {/* {JSON.stringify(data)} */}
        <h2 className="text-midnight font-bold">{$.no_conflict_title}</h2>
        <div className="text-midnight font-bold text-sm">
          {$.no_conflict_info}
        </div>
        <div className="my-2">
          {noConflicted.map((i) => (
            <Field
              key={i.index}
              title={$[i.index + "_field"]}
              valueA={i.vA}
              valueB={i.vB}
              index={i.index}
              selected={selected[i.index]}
              handleSelect={handleSelect}
              talentA={talentA}
              talentB={talentB}
              render={i.render}
              extraA={{
                leadSource: leadSourceA,
              }}
              extraB={{
                leadSource: leadSourceB,
              }}
            />
          ))}
          {linksNoConflicted.map((l) => (
            <Field
              key={l.name}
              title={"Link - " + l.name}
              valueA={l.a?.url}
              valueB={l.b?.url}
              index={"TalentLinks__" + l.name}
              selected={selected["TalentLinks__" + l.name]}
              handleSelect={handleSelectLink}
              talentA={talentA}
              talentB={talentB}
              render={null}
            />
          ))}
          {qualificationsNoConflicted.map((q) => (
            <Field
              key={q.index}
              title={
                "Qualification - " +
                (q.type === "rsu_rating" ? "RSU Rating - " : "Self Rating - ") +
                q.name
              }
              valueA={q.a?.[q.type]}
              valueB={q.b?.[q.type]}
              index={q.index}
              selected={selected[q.index]}
              handleSelect={handleSelect}
              talentA={talentA}
              talentB={talentB}
              render={(v) => <Rating count={v} />}
            />
          ))}
          {!projects.c && (
            <Field
              title="Projects"
              valueA={projects.a}
              valueB={projects.b}
              index="projects"
              selected={selected.projects}
              handleSelect={handleSelect}
              talentA={talentA}
              talentB={talentB}
              render={null}
            />
          )}
          {ownersNoConflicted.map((o) => (
            <Field
              key={o.index}
              title={"Owner"}
              valueA={o.a ? o.name : ""}
              valueB={o.b ? o.name : ""}
              index={"TalentOwners__" + o.index}
              selected={selected["TalentOwners__" + o.index]}
              handleSelect={handleSelect}
              talentA={talentA}
              talentB={talentB}
              render={null}
            />
          ))}
        </div>
        <h2 className="text-midnight font-bold">{$.conflict_title}</h2>
        <div className="text-midnight font-bold text-sm">
          {$.conflict_info}
        </div>
        <div className="my-2">
          {conflicted.map((i) => (
            <Field
              key={i.index}
              title={$[i.index + "_field"]}
              valueA={i.vA}
              valueB={i.vB}
              index={i.index}
              selected={selected[i.index]}
              handleSelect={handleSelect}
              talentA={talentA}
              talentB={talentB}
              render={i.render}
              extraA={{
                leadSource: leadSourceA,
              }}
              extraB={{
                leadSource: leadSourceB,
              }}
            />
          ))}
          {linksConflicted.map((l) => (
            <Field
              key={l.name}
              title={"Link - " + l.name}
              valueA={l.a?.url}
              valueB={l.b?.url}
              index={"TalentLinks__" + l.name}
              selected={selected["TalentLinks__" + l.name]}
              handleSelect={handleSelectLink}
              talentA={talentA}
              talentB={talentB}
              render={null}
            />
          ))}
          {qualificationsConflicted.map((q) => (
            <Field
              key={q.index}
              title={
                "Qualification - " +
                (q.type === "rsu_rating" ? "RSU Rating - " : "Self Rating - ") +
                q.name
              }
              valueA={q.a?.[q.type]}
              valueB={q.b?.[q.type]}
              index={q.index}
              selected={selected[q.index]}
              handleSelect={handleSelect}
              talentA={talentA}
              talentB={talentB}
              render={(v) => <Rating count={v} />}
            />
          ))}
          {projects.c && (
            <Field
              title="Projects"
              valueA={projects.a}
              valueB={projects.b}
              index="projects"
              selected={selected.projects}
              handleSelect={handleSelect}
              talentA={talentA}
              talentB={talentB}
              render={null}
            />
          )}
        </div>
        {(talentA.clockify_id || talentB.clockify_id) && (
          <>
            <h2 className="text-midnight font-bold">{$.clockify_title}</h2>
            <div className="text-midnight font-bold text-sm">
              {$.clockify_info}
            </div>
            <div className="my-2">
              <Field
                title={$.clockify_field}
                valueA={talentA.clockify_id}
                valueB={talentB.clockify_id}
                index="clockify_id"
                selected={selected.clockify_id}
                handleSelect={handleSelect}
                talentA={talentA}
                talentB={talentB}
              />
            </div>
          </>
        )}
        <h2 className="text-midnight font-bold">{$.extra_data_title}</h2>
        <div className="text-midnight font-bold text-sm">
          {$.extra_data_info}
        </div>
        <div className="my-2"></div>
      </div>
      <div className="my-2 flex items-center justify-end space-x-2">
        <Button onClick={onCancel}>{$.cancel_btn}</Button>
        <Button onClick={onSubmit} disabled={!canMerge}>
          <UilUnion size="16" className="mr-1" />
          {$.merge_btn}
        </Button>
      </div>
    </div>
  );
};

const Field = ({
  title,
  index,
  valueA,
  valueB,
  selected,
  handleSelect,
  talentA,
  talentB,
  render,
  extraA,
  extraB,
}) => (
  <div className="flex items-start justify-center space-x-2 mb-3">
    <Option
      title={title}
      value={valueA}
      selected={selected === "A"}
      otherSelected={selected === "B"}
      onSelect={() => handleSelect(index, "A")}
      data={talentA}
      render={render}
      extra={extraA}
    />
    <Option
      title={title}
      value={valueB}
      selected={selected === "B"}
      otherSelected={selected === "A"}
      onSelect={() => handleSelect(index, "B")}
      data={talentB}
      render={render}
      extra={extraB}
    />
  </div>
);

const Option = ({
  selected,
  otherSelected,
  title,
  value,
  onSelect,
  data,
  render,
  extra,
}) => (
  <div
    className={
      "flex-1 rounded-sm py-3 px-2 text-sm flex items-start cursor-pointer border " +
      (otherSelected
        ? "bg-geyser text-caded line-through border-geyser"
        : selected
        ? "border-midnight bg-white text-midnight"
        : "bg-white text-midnight border-white")
    }
    onClick={onSelect}
  >
    <div className="flex-1 font-bold flex items-center">
      {!selected && !otherSelected && <UilSquare size="24" className="mr-2" />}
      {selected && <UilCheck size="24" className="mr-2" />}
      {otherSelected && <UilTimes size="24" className="mr-2" />}
      {title}
    </div>
    <div className="flex-1 mt-px whitespace-pre-line">
      {typeof render === "function"
        ? render(value, data, otherSelected, extra)
        : value}
    </div>
  </div>
);

const Button = ({ children, className = "", disabled = false, ...props }) => (
  <button
    type={props.type || "button"}
    {...props}
    className={"group focus:outline-none h-6 " + className}
    disabled={disabled}
  >
    <span
      className={
        "group-focus:ring focus:outline-none text-sm font-bold rounded px-1 h-6 inline-flex items-center justify-center bg-white " +
        (disabled ? "text-caded cursor-not-allowed " : "text-midnight ") +
        className
      }
      tabIndex="-1"
    >
      {children}
    </span>
  </button>
);

export default Form;
