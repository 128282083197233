import React, { useState } from "react";
import { admin_project_overview as $ } from "strings";
import { toFormat } from "utils/date";
import { deleteProjectNoteV2 } from "utils/adminApi";
import { useAlert } from "react-alert";
import useIsOverflow from "hooks/useIsOverflow";
import AlertError from "components/AlertError";
import EditNoteForm from "./EditNoteForm";
import { urlify } from "utils/str";
import { Link } from "react-router-dom";

const Note = ({ note, editable, updateNote, removeNote, parentRef, className }) => {
  const alert = useAlert();
  const [editing, setEditing] = useState();
  const [showAll, setShowAll] = useState(false);

  const ref = React.useRef();
  const containerRef = React.useRef();
  const isOverflow = useIsOverflow(ref, (v) => {});

  const onDelete = async () => {
    const call = async () => {
      removeNote(note.id);

      try {
        await deleteProjectNoteV2(note.project_id, note.type, note.id);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
      }
    };

    await call();
  };

  const onToggleNote = () => {
    const newVal = !showAll;
    setShowAll(!showAll);
    if (!parentRef.current || !ref.current) return;

    const parentScrollTop = parentRef.current.scrollTop;
    const parentHeight =  parentRef.current.clientHeight;
    const childScrollHeight = ref.current.scrollHeight;
    const childHeight = ref.current.clientHeight;
  
    // how much does the note grow when expanded?
    const expandedNoteDiff = childScrollHeight - childHeight;
    // how much room is there to scroll up in the div?
    const remainingScrollSpace = parentHeight - Math.abs(parentScrollTop);

    var isViewable = remainingScrollSpace > expandedNoteDiff;

    if (!isViewable && !!newVal) {
      const newScrollTop = parentScrollTop - expandedNoteDiff;
      setTimeout(() => {
        document.getElementById("scrollableDiv").scrollTop = newScrollTop
      }, 1);
    }
  };

  const isStageNote = note.note_body?.includes("<table>");

  return (
    <div className={"py-1 " + className}>
      {!editing && (
        <div>
          <div ref={containerRef} className="text-sm font-bold text-midnight flex items-center justify-between">
            <div className="flex-1">
              {note.created_by_name === "Crelate_Populate_Projects_Script"
                ? "Crelate Tags"
                : note.created_by_name ||
                  note.updated_by_name ||
                  $.no_author_label}{" "}
              {$.added_by_label}
              {note.talent_id && note.talent_name ? (
                <>
                  {" "}
                  for{" "}
                  <Link to={`/admin/talent/${note.talent_id}/overview`}>
                    {note.talent_name}
                  </Link>
                  :
                </>
              ) : (
                ": "
              )}
            </div>
            <div className="mr-2 ml-auto">
              {!isStageNote && (isOverflow || showAll) && (
                <button
                  className="focus:outline-none text-link underline text-sm outline-none"
                  onClick={() => onToggleNote()}
                >
                  {showAll ? "Click to Show Less" : "Click to Expand"}
                </button>
              )}
            </div>
          </div>
          <div
            ref={ref}
            className={
              "relative text-sm font-normal text-midnight whitespace-pre-line rich-content min-h-0 " +
              (showAll || isStageNote ? "" : "max-h-20 overflow-y-hidden")
            }
          >
            <div dangerouslySetInnerHTML={{ __html: urlify(note.note_body) }} />
          </div>
          <div className="text-xs text-kasmir flex items-center justify-start">
            <div className="group relative flex items-center">
              {note.created_timestamp && (
                <div className="">
                  {toFormat(
                    new Date(note.created_timestamp),
                    "MMMM dd, yyyy 'at' h:mm a"
                  )}
                </div>
              )}
              {note.updated_at && (
                <div className="bottom-full w-content bg-cove text-white rounded px-1 py-1 text-center absolute z-30 font-bold text-xs hidden group-hover:inline">
                  {$.last_update_label}
                  <br />
                  {toFormat(
                    new Date(note.updated_at),
                    "MMMM dd, yyyy 'at' h:mm a"
                  )}
                </div>
              )}
              {note.created_timestamp &&
                (editable ? <span className="mx-1">|</span> : "")}
            </div>
            {editable && (
              <>
                <button
                  className="focus:outline-none text-xs text-kasmir p-0 outline-none"
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  {$.edit_btn}
                </button>
                <span className="mx-1">|</span>
                <button
                  className="focus:outline-none text-xs text-kasmir p-0 outline-none"
                  onClick={onDelete}
                >
                  {$.delete_btn}
                </button>
              </>
            )}
          </div>
        </div>
      )}
      {editing && (
        <div>
          <div className="text-sm font-bold text-midnight">
            {note.created_by_name === "Crelate_Populate_Projects_Script"
              ? "Crelate Tags"
              : note.created_by_name || $.no_author_label}{" "}
            {$.added_by_label}
          </div>
          <EditNoteForm
            note={note}
            updateNote={updateNote}
            setEditing={setEditing}
          />
        </div>
      )}
    </div>
  );
};

export default Note;
