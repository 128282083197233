import React, { useState } from "react";
import { useForm } from "react-hook-form";
import algoliasearch from "algoliasearch";
import { admin_project_company_form as $ } from "strings";
import { global as $$ } from "strings";
import { Header, Footer } from "components/Card";
import { B2B, B2DB } from "components/Typography";
import { Label, Error, Input } from "components/Form";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import { postCompany } from "utils/adminApi";
import { getAdminUserId } from "utils/localStorageService";
import { usePageCache } from "contexts/pageCacheContext";
import AlertSuccess from "../../../components/AlertSuccess";

const NewCompanyForm = ({ onClose, setModal }) => {
  const { set, cache } = usePageCache();
  const c = cache.admin_project_form || {};
  const [sending, setSending] = useState(false);
  const userId = getAdminUserId();
  const [possibleDuplicates, setPossibleDuplicates] = useState([]);

  const { handleSubmit, register, errors, formState, getValues } = useForm();

  const onSubmit = async (values) => {
    setSending(true);
    try {
      const response = await postCompany({ ...values, owner: userId });
      alert.success(<AlertSuccess message={$$.successful_request_info} />, {timeout: 2000});
      set("admin_project_form", { ...c, company: response });
      onClose();
      setModal("add_project");
    } catch (e) {
      console.error(e);
      setSending(false);
    }
  };

  const checkForDuplicates = async (query) => {
    if (!query || query.length === 0) {
      setPossibleDuplicates([]);
      return;
    }

    const client = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID,
      process.env.REACT_APP_ALGOLIA_SEARCH_KEY
    );
    const idx = client.initIndex("company_index");

    try {
      const response = await idx.search(query, { hitsPerPage: 5 });
      const options = response.hits.map((i) => ({
          company: i,
          value: i.objectID,
          label: i.name,
        })
      );

      setPossibleDuplicates(options);
    } catch (e) {
      console.error(e);
      setPossibleDuplicates([]);
    }
  };

  const DuplicatesSoftWarning = ({ dups }) => {
    const onCompanyClick = (dup) => {
      set("admin_project_form", { ...c, company: dup.company });
      onClose();
      setModal("add_project");
    }

    return (
      <div className="w-full mt-4">
        <B2DB>{$.duplicates_msg}</B2DB>
        {dups.map((d) => (
          <B2B className="py-px cursor-pointer" onClick={() => onCompanyClick(d)}>{d.label}</B2B>
        ))}
      </div>
    )
  }

  return (
    <Modal
      isDirty={formState.isDirty}
      onClose={() => {
        set("admin_project_form", null);
        onClose();
      }}
      id="admin_company_form"
    >
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Header title={$.new_company_title} />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          <div className="w-full pr-8 mb-3 mt-2">
            <Label>{$.company_name_input}</Label>
            <Input
              name="name"
              maxLength="250"
              ref={register({ required: $.validation_required })}
              error={errors.name}
              onBlur={() => checkForDuplicates(getValues("name"))}
            />
            {possibleDuplicates.length > 0 &&
              <DuplicatesSoftWarning dups={possibleDuplicates} />
            }
            {errors.name && <Error>{errors.name.message}</Error>}
          </div>
          <div className="w-full pr-8 mb-3 mt-2">
            <Label>{$.company_website_input}</Label>
            <Input
              name="website"
              type="url"
              maxLength="250"
              error={errors.website}
              ref={register({
                required: $.validation_required,
                pattern: {
                  value:
                    /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,24}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/i,
                  message: $.validation_url,
                },
              })}
            />
            {errors.website && <Error>{errors.website.message}</Error>}
          </div>
        </div>
        <Footer>
          <Button
            secondary
            onClick={() => {
              set("admin_project_form", null);
              onClose();
            }}
            className="mr-4"
          >
            {$.cancel_button}
          </Button>
          <Button type="submit" loading={sending} disabled={sending}>
            {sending ? $.sending_button : $.continue_button}
          </Button>
        </Footer>
      </form>
    </Modal>
  );
};

export default NewCompanyForm;
