import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import { decode } from "html-entities";
import { Link } from "react-router-dom";
import { admin_project_candidate_detail as $ } from "strings";
import { getTimestamp, getDaysText } from "utils/date";
import { withHttp } from "utils/str";
import { useAlert } from "react-alert";
import HubspotToast from "components/HubspotToast";
import userImg from "assets/user.svg";
import userCircleGear from "assets/user_circle_gear.svg";
import Header from "components/ListHeader";
import Select from "components/CompactSelect";
import { Checkbox } from "components/Form";
import { statusOptions, allowedStatusOptions } from './constants';
import CandidateStatusForm from "./CandidateStatusForm";
import UilUserCheck from "@iconscout/react-unicons/icons/uil-user-check";
import UilUserCircle from "@iconscout/react-unicons/icons/uil-user-circle";
import UilClock from "@iconscout/react-unicons/icons/uil-clock";
import UilMoney from "@iconscout/react-unicons/icons/uil-usd-circle";
import UilFile from "@iconscout/react-unicons/icons/uil-file-alt";
import UilFileBookmarkAlt from "@iconscout/react-unicons/icons/uil-file-bookmark-alt";
import UilCalendar from "@iconscout/react-unicons/icons/uil-calender";
import UilLinkedIn from "@iconscout/react-unicons/icons/uil-linkedin";
import UilHeartRate from "@iconscout/react-unicons/icons/uil-heart-rate";
import UilEnvelope from "@iconscout/react-unicons/icons/uil-envelope";


const CandidateListItem = ({
  candidate,
  project,
  openCandidateDetail,
  onUpdate,
  selectedCandidates,
  setSelectedCandidates
}) => {
  const [descriptionString, setDescriptionString] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [hasClip, setHasClip] = useState(false);
  const [modal, setModal] = useState(false);
  const alert = useAlert();

  const grades = ["A+", "A", "A-", "B+", "B", "B-", "C"];
  const gradeOptions = grades.map((g, i) => ({
    label: g,
    value: 8 - i,
  }));
  
  const { 
    id,
    availability_capacity,
    availability_utilization,
    availability_updated_at,
    candidate_user_id,
    creator,
    current_title,
    primary_talent_email,
    excluded,
    last_description,
    minimum_rate,
    name,
    profile_photo,
    projects_count,
    talent_grade,
    calendar_link,
    linkedin_url,
    status
  } = candidate;  

  useEffect(() => {
    if (last_description?.length) {
      const formatted = decode(last_description).replace(/(<([^>]+)>)/gi, " ");
      setDescriptionString(formatted);
    }
  }, [last_description]);

  const copyEmailToClipboard = () => {
    if (!primary_talent_email) return;
    navigator.clipboard.writeText(primary_talent_email);
    setHasClip(true);
    setTimeout(() => {
      setHasClip(false);
    }, 1000);
  }

  const onSelectStatus = (s) => {
    setSelectedStatus(s);
    setModal("changeStatus");
  }

  const onChangeStatusSuccess = () => {
    setModal(false);
    setSelectedCandidates([]);
    onUpdate(candidate.id, candidate.status, selectedStatus);
  }

  const onCheckboxClick = (candidate) => {
    const ids = selectedCandidates.map(c => c.id);
    if (ids.indexOf(candidate.id) > -1) {
      const c = [...selectedCandidates].filter(c => c.id !== candidate.id);
      console.log({c})
      setSelectedCandidates(c);
    } else {
      const c = [...selectedCandidates, candidate];
      console.log({c})
      setSelectedCandidates(c);
    }
  }

  return (
    <div 
      id={`candidate_list_item--${id}`}
      className="bg-white flex-1 mt-2 mb-2 p-2 flex flex-col"
    >
      <div className="w-full flex items-start">
        <Checkbox
          onChange={() => onCheckboxClick(candidate)}
          value={selectedCandidates.find(c => c.id === id) ? true : false}
        />
        <div className="min-h-full flex flex-col items-start w-1/6 gap-2 mx-2">
          <div className="flex max-w-full h-2/3">
            <div className="h-full cursor-pointer" onClick={() => openCandidateDetail(candidate)}>
              <img
                onClick={() => openCandidateDetail(candidate)}
                src={profile_photo || userImg}
                className={clsx("rounded w-12 h-12 mr-2 ",
                  ((!excluded && availability_utilization < 40) && projects_count > 0) && "border-4 border-yellow-dark",
                  (!excluded && availability_utilization >= 40) && "border-4 border-orange-darker",
                  excluded && "border-4 border-red"
                )}
                alt={name}
              />
              {((!excluded && availability_utilization < 40) && projects_count > 0) && 
                <div className="-mt-1 p-1 w-12 text-center bg-yellow-dark text-white text-2xs font-lato rounded">
                  {$.on_active_projects_label}
                </div>
              }
              {(!excluded && availability_utilization >= 40) && 
                <div className="-mt-1 p-1 w-12 text-center bg-orange-darker text-white text-2xs font-lato rounded">
                  {$.overutilized_label}
                </div>
              }
              {excluded && 
                <div className="-mt-1 p-1 w-12 text-center bg-red text-white text-2xs font-lato rounded">
                  {$.excluded_label}
                </div>
              }
            </div>
            <div className="flex flex-col">
                <div className="truncate">
                  <div className="text-sm text-midnight font-bold text-ellipsis font-bold mt-1">
                    {name}
                  </div>
                </div>
                <div className="flex items-center" id={`candidateContact--${candidate_user_id}`}>
                  {linkedin_url && <div className="flex items-center mt-1">
                    <Link
                      id={`linkedinLink--${candidate_user_id}`}
                      className="inline-block cursor-pointer"
                      to={{ pathname: withHttp(linkedin_url) }}
                      target="_blank"
                    >
                      <UilLinkedIn className="text-link" size="20" />
                    </Link>
                  </div>}
                  {linkedin_url && <span className="text-md text-link font-normal px-1">|</span>}
                  <div className="flex items-center mt-1">
                    <Link
                      id={`talentLink--${candidate_user_id}`}
                      className="inline-block cursor-pointer"
                      to={`/admin/talent/${candidate_user_id}/overview`}
                      target="_blank"
                    >
                      <img
                        src={userCircleGear}
                        className="rounded w-5 h-5"
                        alt={name}
                      />
                    </Link>
                  </div>
                  {(calendar_link || primary_talent_email) && <span className="text-md text-link font-normal px-1">|</span>}
                  {calendar_link && <div className="flex items-center mt-1">
                    <Link
                      id={`calendarLink--${candidate_user_id}`}
                      className="inline-block cursor-pointer"
                      to={{ pathname: withHttp(calendar_link) }}
                      target="_blank"
                    >
                      <UilCalendar className="text-link" size="20" />
                    </Link>
                  </div>}
                  {(calendar_link && primary_talent_email) && <span className="text-md text-link font-normal px-1">|</span>}
                  {primary_talent_email && <div className="flex items-center mt-1">
                    <div id={`emailLink--${candidate_user_id}`} className="inline-block cursor-pointer">
                      <UilEnvelope
                        onClick={copyEmailToClipboard}
                        className="text-link"
                        size="20"
                      />
                    </div>
                    {hasClip &&
                      <span className="text-kasmir text-xs font-normal ml-1 italic">
                        {$.copied_clipboard}
                      </span>
                    }
                  </div>}
                </div>
              </div>
          </div>
          <div className="w-full h-1/3 cursor-pointer" onClick={() => openCandidateDetail(candidate)}></div>
        </div>
        <div className="flex flex-col items-start w-1/6 mr-2 gap-2">
          <div className="w-full h-1/2">
            <Header Icon={UilFileBookmarkAlt} text={$.current_role_title} size="14"/>
            <div className="text-xs text-midnight font-bold text-ellipsis mr-1">
              {current_title || $.no_current_title}
            </div>
          </div>
          <div className="w-full h-1/2">
            <Header Icon={UilClock} text={$.availability_title} size="14"/>
            <div className="text-xs text-midnight font-bold text-ellipsis mr-1">
              {availability_capacity && availability_utilization ? availability_capacity - availability_utilization : availability_capacity ? availability_capacity : 0} {$.hours_per_week_abbr} <span className="text-xs text-kasmir">({getDaysText(getTimestamp(availability_updated_at))})</span>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start w-1/6 mr-2 gap-2">
          <div className="w-full h-1/2">
            <Header Icon={UilUserCheck} text={$.talent_grade_title} size="14"/>
            <div className="text-xs text-midnight font-bold text-ellipsis mr-1">
              {talent_grade > 0 ? gradeOptions.find(g => g.value === talent_grade).label : $.empty_value}
            </div>
          </div>
          <div className="w-full h-1/2">
            <Header Icon={UilMoney} text={$.pay_rate_title} size="14"/>
            <div className="text-xs text-midnight font-bold text-ellipsis mr-1">
              ${minimum_rate}{$.per_hour_abbr}
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start w-1/6 mr-2 gap-2">
          <div className="w-full h-1/2">
            <Header Icon={UilUserCircle} text={$.added_by_title} size="14"/>
            <div className="text-xs text-midnight font-bold text-ellipsis mr-1">
              {creator}
            </div>
          </div>
          <div className="w-full h-1/2">
            <Header Icon={UilHeartRate} text={$.status_title} size="14" />
            <div
              className="text-xs text-midnight font-bold text-ellipsis mr-1"
              onClick={() => {
                if (status !== 'scheduled') return;
                alert.success(<HubspotToast type='candidate' objectId={project?.hubspot_deal_id} />, {timeout: 10000});
              }}
            >
              <Select
                className="w-28"
                value={statusOptions.find(o => o.value === status)}
                options={allowedStatusOptions}
                onChange={(v) => onSelectStatus(v?.value)}
                isDisabled={status === 'scheduled'}
                customStyles={{
                  singleValue: (provided) => ({
                    ...provided,
                    fontWeight: "700",
                    fontSize: ".75rem",
                    height: "1.25rem",
                    padding: "0",
                    margin: "0"
                  })
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start w-1/3 mt-1 cursor-pointer" onClick={() => openCandidateDetail(candidate)}>
          <Header Icon={UilFile} text={$.notes_title} size="14"/>
          {descriptionString ?
            <div className="text-xs text-midnight max-h-12 overflow-hidden text-ellipsis">{descriptionString}</div> :
            <div className="text-xs text-kasmir">{$.no_recent_note}</div>
          }
        </div>
      </div>
    {modal === "changeStatus" && (
      <CandidateStatusForm
        project={project}
        data={candidate}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        onClose={setModal}
        onSuccess={onChangeStatusSuccess}
        hubspotInputsDisabled={true}
      />
    )}
    </div>
  )
};

export default CandidateListItem;